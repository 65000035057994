import React from 'react';
import { StudyDesignOverview } from '../index';

const NSCLC_1L_MONO2 = () => (
    
    <>
        <StudyDesignOverview 
            title="Study Overview<sup>5</sup>" 
            description="Phase 3, randomized, multicenter, open-label, active-controlled trial. KEYNOTE&#8288;-&#8288;042 enrolled systemic treatment–naïve patients 
            with stage III NSCLC who were not candidates for surgical resection or definitive chemoradiation, or patients with metastatic NSCLC and whose tumors expressed PD&#8288;-&#8288;L1 
            with a ≥1% TPS. Patients with EGFR or ALK genomic tumor aberrations; autoimmune disease that required systemic therapy within 2 years of treatment; a medical 
            condition that required immunosuppression; or patients who had received more than 30 Gy of thoracic radiation within the prior 26 weeks were ineligible. Patients 
            were randomized to receive KEYTRUDA 200&nbsp;mg intravenously Q3W (n=637) or investigator’s choice of pemetrexed + carboplatin or paclitaxel + carboplatin (n=637; patients with 
            nonsquamous NSCLC could receive pemetrexed maintenance). Treatment continued until progression of disease, unacceptable toxicity, or up to 24 months. The primary 
            efficacy outcome measure was OS. Secondary efficacy outcome measures were PFS and ORR. PFS and ORR were assessed by blinded independent central review (BICR) per 
            Response Evaluation Criteria In Solid Tumors v1.1 (RECIST v1.1) (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ)." 
        />
    </>
);

export default NSCLC_1L_MONO2;