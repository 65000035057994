import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, FeaturedStat, ComparisonTable, KMCurve, SecondaryCallout, EndpointDisplay, NCCNcallout, ReferencesBlock, PatientProfile } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import NSCLC_1L_MONO from '../../components/StudyDesign/__study-design/nsclc-1l-mono'
import NSCLC_1L_MONO2 from '../../components/StudyDesign/__study-design/nsclc-1l-mono-2'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-024 and KEYNOTE-042 - First-line Monotherapy for Advanced NSCLC Clinical Trial Results`,
    keywords: `keynote 024, keynote 042, advanced nsclc first-line monotherapy clinical trial results`,
    description: `Health care professionals may find clinical trial results for first-line monotherapy with KEYTRUDA® (pembrolizumab) for advanced NSCLC in KEYNOTE-024 and KEYNOTE-042.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-first-line-monotherapy/","@type":"ApprovedIndication","name":"Keytruda®","alternateName":"pembrolizumab","description":"KEYTRUDA, as a single agent, is indicated for the first-line treatment of patients with non⁠–⁠small cell lung cancer (NSCLC) expressing programmed death ligand 1 (PD⁠-⁠L1) [tumor proportion score (TPS) ≥1%] as determined by an FDA-approved test, with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations, and is: stage III where patients are not candidates for surgical resection or definitive chemoradiation, or metastatic."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-first-line-monotherapy/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 6;
const jobCode = jobCodes[4].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/efficacy/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Combo Therapy – Squamous', url: '/efficacy/nsclc-squamous-first-line-combination-therapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/efficacy/nsclc-neoadjuvant-therapy/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/efficacy/nsclc-adjuvant-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-first-line-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' }
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;024' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;024' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' },
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;042' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;042' },
    { label: 'Appropriate Patients' }
]

const featuredStatFootnotes = [
    {
        label: "a.",
        text: "HR based on the stratified Cox proportional hazard model for the interim analysis; <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "b.",
        text: "<i>P</i> value is compared with 0.0118 of the allocated alpha for the interim analysis."
    }
]

const osSurvivalComparisonTableFootnotes = [
    {
        label: "c.",
        text: "HR based on the stratified Cox proportional hazard model for the interim analysis; <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "d.",
        text: "<i>P</i> value is compared with 0.0118 of the allocated alpha for the interim analysis."
    }
]

const interimAnalysisPFSComparisonTableFootnotes = [
    {
        label: "i.",
        text: "HR based on the stratified Cox proportional hazard model for the interim analysis; <i>P</i> value based on a stratified log-rank test."
    }
]

const interimAnalysisORRComparisonTableFootnotes = [
    {
        label: "j.",
        text: "<i>P</i> value based on stratified Miettinen-Nurminen test."
    }
]

const protocolSpecifiedFinalOsFootnotes = [
    {
        label: "e.",
        text: "Based on the protocol-specified final OS analysis conducted at 169 events, which occurred 14 months after the interim analysis."
    }
]

const explorAnalysisOsSurvivalComparisonTableFootnotes = [
    {
        label: "f.",
        text: "ITT population."
    },
    {
        label: "g.",
        text: "Out of 151 patients randomized in the chemotherapy arm, 150 patients were treated."
    },
    {
        label: "h.",
        text: "Patients may have received >1 subsequent anti–PD&#8288;-&#8288;(L)1 therapy."
    }
]

const NCCNcalloutData = {
    title: 'Category 1 and PREFERRED recommendation for patients with PD&#8288;-&#8288;L1 ≥50%<sup>4,k,l</sup>',
    bodyCopy: 'Single-agent pembrolizumab (KEYTRUDA) is recommended (Category 1 and Preferred) as first-line therapy for patients with nonsquamous or squamous mNSCLC, and with no EGFR mutations or ALK rearrangements.',
    showBadge: false,
    alt: ''
}

const NCCNFootnotes = [
    {
        label: "k.",
        text: "See NCCN Guidelines® for detailed recommendations, including combination regimens, in patients with no targetable genomic tumor aberrations."
    },
    {
        label: "l.",
        text: "See NCCN Guidelines® for recommendations specific to genetic alterations, including EGFR, ALK, ROS1, BRAF, MET, KRAS, NTRK1/2/3, RET, and ERBB2 (HER2)."
    }
]

const NCCNcalloutReferences = [
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.4.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed October 18, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const NCCNdefinitions = `<p>According to NCCN, preferred intervention = Intervention that is based on superior efficacy, safety, and evidence, and, when appropriate, affordability; Category 1 = Based upon high-level evidence, there is uniform NCCN consensus that the intervention is appropriate.</p>
                        <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
                        <p>BRAF = B-Raf proto-oncogene, serine/threonine kinase; ERBB2 = Erb-B2 receptor tyrosine kinase 2; KRAS = KRAS proto-oncogene, GTPase; MET = MET proto-oncogene receptor tyrosine kinase; NTRK = neurotrophic tyrosine receptor kinase; RET = rearranged during transfection; ROS1 = ROS proto-oncogene 1, receptor tyrosine kinase.</p>`

const clinicalFindingsKey042OSPDL1ComparisonTableFootnotes = [
    {
        label: "m.",
        text: "HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test; compared to a <i>P</i>-value boundary of 0.0291."
    }
]

const clinicalFindingsKey042OSPDL50ComparisonTableFootnotes = [
    {
        label: "n.",
        text: "HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test; compared to a <i>P</i>-value boundary of 0.0291."
    }
]

const osForPdl1Footnotes = [
    {
        label: "o.",
        text: "HR was assessed using a stratified Cox proportional hazard model with the Efron's method of tie handling.<sup>5</sup>"
    }
]

const osForPdl150PercentFootnotes = [
    {
        label: "p.",
        text: "HR was assessed using a stratified Cox proportional hazard model with the Efron's method of tie handling.<sup>5</sup>"
    }
]

const clinicalFindingsKey042PFSPDL1ComparisonTableFootnotes = [
    {
        label: "q.",
        text: "HR based on the stratified Cox proportional hazard model."
    },
    {
        label: "r.",
        text: "Not evaluated for statistical significance as a result of the sequential testing procedure for the secondary end points."
    }
]

const clinicalFindingsKey042PFSPDL50ComparisonTableFootnotes = [
    {
        label: "s.",
        text: "HR based on the stratified Cox proportional hazard model."
    },
    {
        label: "t.",
        text: "<i>P</i> value based on a stratified log-rank test; compared to a <i>P</i>-value boundary of 0.0291."
    },
    {
        label: "u.",
        text: "Not significant compared to a <i>P</i>-value boundary of 0.0291."
    }
]

const clinicalFindingsKey042ORRPDL1ComparisonTableFootnotes = [
    {
        label: "v.",
        text: "Not evaluated for statistical significance as a result of the sequential testing procedure for the secondary end points."
    },
    {
        label: "w.",
        text: "Based on observed duration of response."
    }
]

const clinicalFindingsKey042ORRPDL50ComparisonTableFootnotes = [
    {
        label: "x.",
        text: "Not evaluated for statistical significance as a result of the sequential testing procedure for the secondary end points."
    },
    {
        label: "y.",
        text: "Based on observed duration of response."
    }
]

const patientProfileHelen = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-helen@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Helen",
    diagnosis: "Squamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '20%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Bone',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '73',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
        {
            key: 'Smoking Status',
            value: 'Current smoker',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileScott = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-scott@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Scott",
    diagnosis: "Nonsquamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'EGFR/ALK',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '10%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Brain',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '62',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking History',
            value: '20-pack-year history, quit 13 years ago',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileBarbara = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-barbara@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Barbara",
    diagnosis: "Stage III nonsquamous NSCLC, not a candidate for surgical resection or definitive chemoradiation",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'EGFR/ALK',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '10%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Not detected',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '63',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking History',
            value: '17-pack-year history, quit 11 years ago',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const pageReferences = [
    {
        label: "1.",
        text: "Reck M, Rodríguez-Abreu D, Robinson AG, et al; for the KEYNOTE&#8288;-&#8288;024 investigators. Pembrolizumab versus chemotherapy for PD&#8288;-&#8288;L1–positive non–small-cell lung cancer. <i>N Engl J Med.</i> 2016;375(19):1823–1833."
    },
    {
        label: "2.",
        text: "Reck M, Rodríguez-Abreu D, Robinson AG, et al. Updated analysis of KEYNOTE-024: pembrolizumab versus platinum-based chemotherapy for advanced non–small-cell lung cancer with PD&#8288;-&#8288;L1 tumor proportion score of 50% or greater. <i>J Clin Oncol.</i> 2019;37(7):537–546."
    },
    {
        label: "3.",
        text: "Data available on request from Merck & Co., Inc., Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-LAM-03476."
    },
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.4.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed October 18, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
    {
        label: "5.",
        text: "Mok TSK, Wu Y-L, Kudaba I, et al; for the KEYNOTE&#8288;-&#8288;042 investigators. Pembrolizumab versus chemotherapy for previously untreated, PD&#8288;-&#8288;L1-expressing, locally advanced or metastatic non-small-cell lung cancer (KEYNOTE&#8288;-&#8288;042): a randomised, open-label, controlled, phase 3 trial. <i>Lancet.</i> 2019;393(10183):1819–1830."
    },
    {
        label: "6.",
        text: "de Castro G Jr, Kudaba I, Wu YL, et al. Five-year outcomes with pembrolizumab versus chemotherapy as first-line therapy in patients with non-small-cell lung cancer and programmed death ligand-1 tumor proportion score ≥ 1% in the KEYNOTE-042 study. <i>J Clin Oncol.</i> 2023;41(11):1986-1991. doi:10.1200/JCO.21.02885"
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Superior Overall Survival With KEYTRUDA as First-line Monotherapy vs Platinum-containing Chemotherapy</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Interim Analysis: Overall Survival (PD&#8288;-&#8288;L1 ≥50%; Median Follow-up Time: 11.2 Months)<sup>1</sup>">
                                <FeaturedStat
                                    statistic="40%"
                                    title="Reduction in the risk of death with KEYTRUDA vs platinum-containing chemotherapy<sup>a,b</sup>"
                                    hr="HR=0.60 (95% CI, 0.41–0.89; <i>P</i>=0.005)"
                                    footnotes={featuredStatFootnotes}
                                />

                                <ComparisonTable
                                    title="Overall Survival (OS)"
                                    footnotes={osSurvivalComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=154)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=151)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.60 (95% CI, 0.41–0.89; <i>P</i>=0.005)<sup>c,d</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '44',
                                                    dataSubPoint: '(29%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '64',
                                                    dataSubPoint: '(42%)'
                                                },

                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="Protocol-Specified Final OS Analysis (Median Follow-up Time: 25.2 Months)<sup>2,e</sup>">
                                <ComparisonTable
                                    title="Overall Survival (OS)"
                                    definitions="NR = not reached."
                                    footnotes={protocolSpecifiedFinalOsFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=154)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=151)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '30.0 months',
                                                    dataSubPoint: '(95% CI, 18.3–NR)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '14.2 months',
                                                    dataSubPoint: '(95% CI, 9.8–19.0)'
                                                },

                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="Exploratory Analysis (Median Follow-up Time: 59.9 Months)<sup>3</sup>">
                                <KMCurve
                                    title="Overall Survival (OS) in Patients With PD&#8288;-&#8288;L1 TPS&nbsp;≥50%<sup>f</sup>"
                                    limitation="<p><b>LIMITATION:</b> The post hoc analysis (median follow-up time: 59.9 months) in KEYNOTE&#8288;-&#8288;024 was exploratory in nature and occurred after the protocol-specified final analysis. No formal statistical testing was planned for this updated analysis and, therefore, no statistical conclusions can be drawn. Trial participants in either study arm could receive subsequent anti-cancer therapy.</p>
                                    <p><b>CROSSOVER RATE:</b> 66.0% (99/151) of patients crossed over from chemotherapy to anti–PD&#8288;-&#8288;(L)1 therapy on (n=83) or off (n=16) study.<sup>g,h</sup></p>"
                                    hr="HR=0.62 (95% CI, 0.48–0.81)"
                                    image={() => <StaticImage src="../../assets/nsc-1-lmono-kn-407-km-curve-50-percent-v-4.webp"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Curve Showing Overall Survival (OS) in Patients With PD-L1 ≥50% in KEYNOTE-024" />}
                                />
                                <ComparisonTable
                                    title="Overall Survival (OS)"
                                    footnotes={explorAnalysisOsSurvivalComparisonTableFootnotes}
                                    definitions="ITT = intent to treat."
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=154)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=151)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '103',
                                                    dataSubPoint: '(67%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '123',
                                                    dataSubPoint: '(82%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '26.3 months',
                                                    dataSubPoint: '(95% CI, 18.3–40.4)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '13.4 months',
                                                    dataSubPoint: '(95% CI, 9.4–18.3)'
                                                },
                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="Interim Analysis: PFS and ORR (Median Follow-up Time: 11.2 Months)<sup>1</sup>">
                                <SecondaryCallout 
                                    title="Superior PFS and ORR with KEYTRUDA vs platinum-containing chemotherapy in an interim analysis of KEYNOTE&#8288;-&#8288;024" 
                                    alt=""
                                />
                                <ComparisonTable
                                    title="Progression-Free Survival (PFS): Risk of Disease Progression or Death Reduced by Half With KEYTRUDA vs Platinum-Containing Chemotherapy"
                                    footnotes={interimAnalysisPFSComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=154)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=151)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.50 (95% CI, 0.37–0.68; <i>P</i><0.001)<sup>i</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '73',
                                                    dataSubPoint: '(47%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '116',
                                                    dataSubPoint: '(77%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '10.3 months',
                                                    dataSubPoint: '(95% CI, 6.7–NR)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '6.0 months',
                                                    dataSubPoint: '(95% CI, 4.2–6.2)'
                                                },
                                            ]
                                        ]
                                    } />

                                <EndpointDisplay
                                    title="Objective Response Rate (ORR): Nearly Half of Patients Responded With KEYTRUDA<sup>1</sup>"
                                    limitation="In 154 participants in the trial with KEYTRUDA vs in 151 participants with platinum-containing chemotherapy</p>"
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '45%',
                                                ci: '95% CI, 37–53',
                                                p: '0.001<sup>j</sup>',
                                                n: '69/154',
                                                completeResponseRate: '4%',
                                                partialResponseRate: '41%',
                                            },

                                            {
                                                dataType: 'ORR',
                                                label: 'Platinum-containing chemotherapy',
                                                rate: '28%',
                                                ci: '95% CI, 21–36',
                                                n: '42/151',
                                                completeResponseRate: '1%',
                                                partialResponseRate: '27%',
                                            }
                                        ]
                                    }
                                />

                                <ComparisonTable
                                    title="Duration of Response (DOR)"
                                    footnotes={interimAnalysisORRComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=154)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=151)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: 'Not reached',
                                                    dataSubPoint: '(range: 1.9+ – 14.5+ months)'
                                                },
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '6.3 months',
                                                    dataSubPoint: '(range: 2.1+ – 12.6+ months)'
                                                },

                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <NSCLC_1L_MONO />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                references={NCCNcalloutReferences}
                                footnotes={NCCNFootnotes}
                                definitions={NCCNdefinitions}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Interim Analysis (Median Follow-up Time: 12.8 Months)<sup>5</sup>">
                                <SecondaryCallout 
                                    title="Superior overall survival first line with KEYTRUDA as monotherapy vs platinum-containing chemotherapy" 
                                    text="In patients with stage III NSCLC who were not candidates for surgical resection or definitive chemoradiation, or patients with mNSCLC, with PD&#8288;-&#8288;L1 expression (≥1%) and no EGFR or ALK genomic tumor aberrations" 
                                    alt=""
                                />
                                <ComparisonTable
                                    title="Overall Survival (OS) for PD&#8288;-&#8288;L1 TPS&nbsp;≥1%"
                                    additionalInformation={[
                                        {
                                            title: 'A 4.6-month improvement in median overall survival (OS)',
                                        },
                                    ]}  
                                    footnotes={clinicalFindingsKey042OSPDL1ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=637)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=637)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.81 (95% CI, 0.71–0.93; <i>P</i>=0.0036)<sup>m</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Number of Events',
                                                    dataPoint: '371',
                                                    dataSubPoint: '(58%)'
                                                },
                                                {
                                                    label: 'Number of Events',
                                                    dataPoint: '438',
                                                    dataSubPoint: '(69%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '16.7 months',
                                                    dataSubPoint: '(95% CI, 13.9–19.7)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '12.1 months',
                                                    dataSubPoint: '(95% CI, 11.3–13.3)'
                                                },
                                            ]
                                        ]
                                    } />

                                <ComparisonTable
                                    title="Overall Survival (OS) for PD&#8288;-&#8288;L1 TPS&nbsp;≥50%"
                                    footnotes={clinicalFindingsKey042OSPDL50ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=299)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=300)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.69 (95% CI, 0.56–0.85; <i>P</i>=0.0006)<sup>n</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Number of Events',
                                                    dataPoint: '157',
                                                    dataSubPoint: '(53%)'
                                                },
                                                {
                                                    label: 'Number of Events',
                                                    dataPoint: '199',
                                                    dataSubPoint: '(66%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '20.0 months',
                                                    dataSubPoint: '(95% CI, 15.4–24.9)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '12.2 months',
                                                    dataSubPoint: '(95% CI, 10.4–14.2)'
                                                },
                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="Exploratory Analysis (Median Follow-up Time: 61.1 Months)<sup>6</sup>">

                                <ComparisonTable
                                    title="Overall Survival (OS) for PD⁠-⁠L1 TPS&nbsp;≥1%"
                                    limitation={`
                                        <p style="margin-top: 0;"><b>LIMITATION:</b> This post-hoc analysis (median follow-up time: 61.1 months) in KEYNOTE&#8288;-&#8288;042 was exploratory in nature. No formal statistical testing was planned for the updated analysis and, therefore, no statistical conclusions can be drawn. Trial participants in either study arm could receive subsequent anti-cancer therapy.</p>
                                        <p style="margin-bottom: 0;"><b>CROSSOVER:</b> Crossover was not permitted in the KEYNOTE&#8288;-&#8288;042 study.<sup>6</sup></p>
                                    `}
                                    footnotes={osForPdl1Footnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=637)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=637)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR = 0.79; 95% CI, 0.70–0.89<sup>o</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '530',
                                                    dataSubPoint: '(83%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '575',
                                                    dataSubPoint: '(90%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '16.4 months',
                                                    dataSubPoint: '(95% CI, 14.0–19.6)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '12.1 months',
                                                    dataSubPoint: '(95% CI, 11.3–13.3)'
                                                },
                                            ],
                                        ]
                                    } />
                                    <ComparisonTable
                                    title="Overall Survival (OS) for PD&#8288;-&#8288;L1 TPS&nbsp;≥50%"
                                    limitation={`
                                        <p style="margin-top: 0;"><b>LIMITATION:</b> This post-hoc analysis (median follow-up time: 61.1 months) in KEYNOTE&#8288;-&#8288;042 was exploratory in nature. No formal statistical testing was planned for the updated analysis and, therefore, no statistical conclusions can be drawn. Trial participants in either study arm could receive subsequent anti-cancer therapy.</p>
                                        <p style="margin-bottom: 0;"><b>CROSSOVER:</b> Crossover was not permitted in the KEYNOTE&#8288;-&#8288;042 study.<sup>6</sup></p>
                                    `}
                                    footnotes={osForPdl150PercentFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=299)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=300)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR = 0.68; 95% CI, 0.57–0.81<sup>p</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '234',
                                                    dataSubPoint: '(78%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '268',
                                                    dataSubPoint: '(89%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '20.0 months',
                                                    dataSubPoint: '(95% CI, 15.9–24.2)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '12.2 months',
                                                    dataSubPoint: '(95% CI, 10.4–14.6)'
                                                },
                                            ],
                                        ]
                                    }
                                />
                            </PageSubSection>
                            
                            <PageSubSection title="Interim Analysis (Median Follow-up Time: 12.8 months)<sup>5</sup>">
                                {/* PFS TABLES */}
                                <ComparisonTable
                                    title="Progression-Free Survival (PFS) for PD&#8288;-&#8288;L1 TPS&nbsp;≥1%"
                                    footnotes={clinicalFindingsKey042PFSPDL1ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=637)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=637)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=1.07 (95% CI, 0.94–1.21)<sup>q,r</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '507',
                                                    dataSubPoint: '(80%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '506',
                                                    dataSubPoint: '(79%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '5.4 months',
                                                    dataSubPoint: '(95% CI, 4.3–6.2)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '6.5 months',
                                                    dataSubPoint: '(95% CI, 6.3–7.0)'
                                                },
                                            ]
                                        ]
                                    } />

                                <ComparisonTable
                                    title="Progression-Free Survival (PFS) for PD&#8288;-&#8288;L1 TPS&nbsp;≥50%"
                                    footnotes={clinicalFindingsKey042PFSPDL50ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=299)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=300)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.82 (95% CI, 0.68–0.99; <i>P</i>=NS)<sup>s-u</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '221',
                                                    dataSubPoint: '(74%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '233',
                                                    dataSubPoint: '(78%)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '6.9 months',
                                                    dataSubPoint: '(95% CI, 5.9–9.0)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '6.4 months',
                                                    dataSubPoint: '(95% CI, 6.1–6.9)'
                                                },
                                            ]
                                        ]
                                    } />

                                {/* ORR TABLES */}
                                <ComparisonTable
                                    title="Objective Response Rate (ORR) for PD&#8288;-&#8288;L1 TPS&nbsp;≥1%<sup>v</sup>"
                                    footnotes={clinicalFindingsKey042ORRPDL1ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=637)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=637)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '27%',
                                                    dataSubPoint: '(95% CI, 24–31)'
                                                },
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '27%',
                                                    dataSubPoint: '(95% CI, 23–30)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.5%'
                                                },
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.5%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '27%'
                                                },
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '26%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: '% of Responders With Duration ≥12 Months<sup>w</sup>',
                                                    dataPoint: '47%'
                                                },
                                                {
                                                    label: '% of Responders With Duration ≥12 Months<sup>w</sup>',
                                                    dataPoint: '16%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: '% of Responders With Duration ≥18 Months<sup>w</sup>',
                                                    dataPoint: '26%'
                                                },
                                                {
                                                    label: '% of Responders With Duration ≥18 Months<sup>w</sup>',
                                                    dataPoint: '6%'
                                                },
                                            ],

                                        ]
                                    } />

                                <ComparisonTable
                                    title="Objective Response Rate (ORR) for PD&#8288;-&#8288;L1 TPS&nbsp;≥50%<sup>x</sup>"
                                    footnotes={clinicalFindingsKey042ORRPDL50ComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=299)',
                                                },
                                                {
                                                    legendTitle: 'Platinum-containing chemotherapy (n=300)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '39%',
                                                    dataSubPoint: '(95% CI, 33.9–45.3)'
                                                },
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '32%',
                                                    dataSubPoint: '(95% CI, 26.8–37.6)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.7%'
                                                },
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.3%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '39%'
                                                },
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '32%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: '% of Responders With Duration ≥12 Months<sup>y</sup>',
                                                    dataPoint: '42%'
                                                },
                                                {
                                                    label: '% of Responders With Duration ≥12 Months<sup>y</sup>',
                                                    dataPoint: '17%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: '% of Responders With Duration ≥18 Months<sup>y</sup>',
                                                    dataPoint: '25%'
                                                },
                                                {
                                                    label: '% of Responders With Duration ≥18 Months<sup>y</sup>',
                                                    dataPoint: '5%'
                                                },
                                            ],

                                        ]
                                    } 
                                />
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[4].label}>
                                <NSCLC_1L_MONO2 />
                            </PageSubSection>

                        </PageSection>

                        <PageSection title={anchorLinkModelData[5].label}>
                            <PageSubSection title="Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;042">
                                <PatientProfile {...patientProfileHelen} definitions="mNSCLC = metastatic NSCLC; ECOG = Eastern Cooperative Oncology Group." />
                                <PatientProfile {...patientProfileScott} definitions="mNSCLC = metastatic NSCLC; EGFR = epidermal growth factor receptor; ALK = anaplastic lymphoma kinase; ECOG = Eastern Cooperative Oncology Group." />
                                <PatientProfile {...patientProfileBarbara} definitions="mNSCLC = metastatic NSCLC; ECOG = Eastern Cooperative Oncology Group; EGFR = epidermal growth factor receptor; ALK = anaplastic lymphoma kinase." />
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
